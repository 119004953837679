import React from "react";
import LogRocket from "logrocket";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { GlobalContextProvider } from "./src/context/GlobalContextProvider";
import { ModalProvider } from "react-modal-hook";
import { SnackBarProvider } from "./src/context/snackbar-provider";
import { TransitionGroup } from "react-transition-group";

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <SnackBarProvider>
      <ModalProvider rootComponent={TransitionGroup}>{element}</ModalProvider>
    </SnackBarProvider>
  </GlobalContextProvider>
);

export const onClientEntry = () => {
  if (process.env.NODE_ENV === "production") {
    LogRocket.init("llpoxs/ccc");
  }
};
