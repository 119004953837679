import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";

import { SnackBar } from "../components/SnackBar";

export const SnackBarContext = createContext();

const AUTO_DISMISS = 5000;

export function SnackBarProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  const activeAlertIds = alerts.join(",");
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS
      );
      return () => clearTimeout(timer);
    }
  }, [activeAlertIds]);

  const addAlert = useCallback(
    (content) => setAlerts((alerts) => [content, ...alerts]),
    []
  );

  const value = useMemo(() => ({ addAlert }), [addAlert]);

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert) => (
        <SnackBar key={alert} open={true}>
          {alert}
        </SnackBar>
      ))}
    </SnackBarContext.Provider>
  );
}
