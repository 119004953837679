import { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import firebase from "gatsby-plugin-firebase";

const useOnProductInventoryChanged = (collection, user) => {
  const [productInventory, setProductInventory] = useState({});

  const {
    data: { products },
  } = useStaticQuery(graphql`
    query InventoryQuery {
      data: allStripePrice {
        products: group(field: product___id) {
          prices: nodes {
            id
            unit_amount
            description: nickname
          }
          id: fieldValue
        }
      }
    }
  `);

  const handleOnSnapshot = (docs) => {
    const updates = {};
    docs
      .map((doc) => doc.data())
      .forEach((item) => {
        updates[item.uid] = {};
        if(item.includesLiveVideo && item.videoUrl) {
          updates[item.uid]['videoUrl'] = {...item.videoUrl}
        }
        if (!item.product.stripeID || !item.uid) return;
        updates[item.uid]["product"] = item.product.stripeID;
        const prices = [];
        const stripeProduct = products.find((product) => {
          return product.id === item.product.stripeID;
        });
        if (!stripeProduct) {
          return console.error(
            "Item did not create connection to stripe:",
            item.title
          );
        }

        stripeProduct.prices.forEach((price) => {
          const inventoryItem = item.product.prices.find(
            (dbPrice) => dbPrice.id === price.id
          );
          if (!inventoryItem)
            return console.error(
              "price has no inventory",
              item.title,
              price.id
            );
          const inventory = parseInt(inventoryItem.inventory);
          const result = {
            id: price.id,
            startingInventory: inventory,
            inStock: true,
            remaining: inventory,
          };
          if (item.purchases && item.purchases[price.id]) {
            result["inStock"] = inventory > item.purchases[price.id];
            result["remaining"] = inventory - item.purchases[price.id];
            result["purchases"] = item.purchases[price.id];
          }
          prices.push(result);
        });
        updates[item.uid]["prices"] = prices;
      });

    setProductInventory({
      ...productInventory,
      ...updates,
    });
  };
  // events
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(collection)
      .onSnapshot((snapshot) => {
        if(!user) return;
        handleOnSnapshot(snapshot.docs);
      });
    return () => unsubscribe();
  }, [user]);
  return productInventory;
};

export default useOnProductInventoryChanged;
