import { useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";
import LogRocket from "logrocket";

const useOnAuthStateChanged = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async function(user) {
        if (user) {
          await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .onSnapshot((doc) => {
              if (!doc) return;
              const docData = doc.data();
              const result = { ...docData };
              if (docData && docData.tags && docData.tags.length)
                result.tags = [...docData.tags];
              LogRocket.identify(user.uid, {
                name: result.fullName,
                email: result.email,
                purchases: result.purchases,
                events: result.events,
              });
              setCurrentUser(result);
            });
        } else {
          setCurrentUser(false);
        }
      });
    return () => unsubscribe();
  }, []);

  return currentUser;
};

export default useOnAuthStateChanged;
