import React, { useState } from "react";

import useOnAuthStateChanged from "../hooks/useOnAuthStateChanged";
import useOnProductInventoryChanged from "../hooks/useOnProductInventoryChanged";

export const myContext = React.createContext();

export const GlobalContextProvider = (props) => {
  const [isAppLoaded, setAppLoaded] = useState(false);

  const user = useOnAuthStateChanged();
  const eventsData = useOnProductInventoryChanged("events", user);
  const cafeteriaInventory = useOnProductInventoryChanged("cafeteriaItems", user);

  return (
    <myContext.Provider
      value={{
        isAppLoaded,
        eventsData,
        cafeteriaInventory,
        user,
        toggleAppLoaded: () => setAppLoaded(true),
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};
